
.basic {
    background-color: #f5f5f5;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
  }
  
.basic .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
.basic .description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
.basic .info {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
.basic .link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
.basic .link:hover {
    color: darkblue;
  }