

.news-widget-container {
  max-width: 650px;
  width: 650px;
  margin: 2rem auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.news-widget-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.news-widget-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.news-widget-item a {
  text-decoration: none;
}

.news-widget-item a:hover {
  text-decoration: underline;
}

.news-widget-date {
  font-size: 0.8rem;
  color: #999;
}