/* styles/projects/GLPIProject.css */


.App {
  background-color: #00021a;/* Remplacez par la couleur souhaitée */
}

.pdf-container {
  width: 100%;
  max-width: 800px; /* Ajustez la taille en fonction de la taille souhaitée de la fenêtre */
  margin: 20px auto;
  border: 1px solid #ccc;
  padding: 10px;
}

.pdf-navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pdf-navigation button {
  margin: 0 5px;
}

.pdf-download-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pdf-download-button a {
  background-color: #29a17f;
  color: #fff;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 4px;
}

.pdf-download-button a:hover {
  background-color: #999;
}

.glpi {
    background-color: #f5f5f5;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .glpi .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .medsell .description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .glpi .info {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .glpi .link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .glpi .link:hover {
    color: darkblue;
  }

  .image-container {
    display: flex;
    justify-content: center;
  }
  
  .image-container img {
    max-width: 100%;
    max-height: 100%;
  }

  .image-label {
    text-align: center;
  }

  