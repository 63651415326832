/* styles/projects/Projects.css */

.projects {
  background-color: #f5f5f5;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.projects .title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.projects .description {
  font-size: 16px;
  margin-bottom: 20px;
}

.projects .info {
  font-size: 14px;
  margin-bottom: 10px;
}

.projects .link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.projects .link:hover {
  color: darkblue;
}

.pdf-download-button {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.pdf-download-button .download-button {
  background-color: #29a17f;
  color: #fff;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 4px;
  margin-right: 10px;
}

.pdf-download-button .download-button:hover {
  background-color: #999;
}

h3 {
  text-align: center;
}